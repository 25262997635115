import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import { InputBoxBorderRadius, LaunchButtonBorderRadius } from '../../MaterialUiThemes/themes'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'

export const ConfirmButton = styled(LoadingButton)(({ theme }) => ({
    padding: theme.spacing(1),
    borderRadius: LaunchButtonBorderRadius,
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    margin: 'auto',
}))

export const ActionButton = styled(LoadingButton)(({ theme }) => ({
    padding: theme.spacing(1),
    borderRadius: LaunchButtonBorderRadius,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    margin: 'auto',
    whiteSpace: 'nowrap',
    textTransform: 'none',
}))
