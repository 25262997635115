import React, { useEffect, useState } from 'react'
import { getStoredAuthToken } from '../../utils/auth-token'
import { Box, Divider, ThemeProvider, Theme, StyledEngineProvider, Typography } from '@mui/material'
import { ActionButton } from '../input/styleOverrides'
import { Link } from 'gatsby'
declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

const customTheme = (outerTheme) => ({
    ...outerTheme,
    palette: {
        ...outerTheme.palette,
        secondary: {
            main: '#7EAC55',
            contrastText: '#fff',
        },
    },
})

export default function SignupBelt({ includeLandingRef = false }) {
    const [authToken] = useState(getStoredAuthToken())
    const [signupButton, setSignupButton] = useState(buildSignupButton(authToken))

    function buildSignupButton(authToken) {
        return (
            <a href={!!authToken ? '/app' : '/signup'}>
                <ActionButton color="secondary" variant="contained">
                    Prøv gratis i 30 dage
                </ActionButton>
            </a>
        )
    }

    useEffect(() => {
        setSignupButton(buildSignupButton(authToken))
    }, [authToken])

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={customTheme}>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        backgroundColor: '#D7E6F5',
                        padding: 10,
                        marginBottom: 20,
                        paddingTop: 1,
                    }}
                >
                    <Divider style={{ opacity: 0 }} />
                    <Typography fontWeight={550} fontSize={22}>
                        Få orden i din compliance
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: 18, textAlign: 'center', px: 1 }}>
                        Fra 125,- kr. per måned
                    </Typography>
                    <Box sx={{ display: 'flex' }}>
                        {includeLandingRef && (
                            <Box sx={{ mr: 1 }}>
                                <Link to="/">
                                    <ActionButton color="primary" variant="outlined">
                                        Læs mere
                                    </ActionButton>
                                </Link>
                            </Box>
                        )}
                        <Box sx={{ mr: 1 }}>{signupButton}</Box>
                    </Box>
                    <Divider style={{ opacity: 0 }} />
                </div>
            </ThemeProvider>
        </StyledEngineProvider>
    )
}
