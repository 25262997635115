import React from 'react'
import { Anchor, Divider } from 'antd'
import Container from '../components/Landing/Container'
import { CalendarOutlined } from '@ant-design/icons'
import { isMobile } from 'react-device-detect'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/seo'
import makeStyles from '@mui/styles/makeStyles'
import { ExternalLink } from '../components/BlogPost/Texts'
import SignupBelt from '../components/Landing/SignupBelt'

const { Link } = Anchor

const useStyles = makeStyles((theme) => ({
    smallText: {
        color: 'gray',
    },
    italic: {
        fontStyle: 'italic',
    },
}))

export default function UdlandsopholdGuidePage({ setCookieValue, data }) {
    const classes = useStyles()
    const affixContainerStyle: React.CSSProperties = isMobile
        ? {}
        : { display: 'grid', gridTemplateColumns: '1fr 6fr 1fr', marginLeft: 10, marginRight: 10 }

    return (
        <Container setCookieValue={setCookieValue} pathName="udlandsophold">
            <SEO title="Udlandsophod Guide" />
            <span style={affixContainerStyle}>
                {!isMobile && (
                    <Anchor offsetTop={100} targetOffset={156}>
                        <Link href="#guide-title" title="Udlandsophold">
                            <Link href="#step-1" title="1. Kalenderen" />
                            <Link href="#step-2" title="2. Udlandsophold - menuen" />
                            <Link href="#step-3" title="3. Titel" />
                            <Link href="#step-4" title="4. Start dato" />
                            <Link href="#step-5" title="5. Udrejsedagen" />
                            <Link href="#step-6" title="6. Slut dato" />
                            <Link href="#step-7" title="7. Gem" />
                            <Link href="#step-8" title="8. Rediger" />
                        </Link>
                    </Anchor>
                )}
                <div>
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            // flexWrap: 'wrap',
                            marginBottom: 100,
                        }}
                        id="guide-title"
                    >
                        <h1 style={{ fontSize: 40 }} className="landing-guide-title-h">
                            Udlandsophold
                        </h1>
                        <p className="landing-guide-header-p">
                            Et udlandsophold er en defineret periode, hvor du kan opnå
                            skattenedsættelse. Perioden hvori du opholder dig i udlandet, skal have
                            en varighed på mindst 6 måneder, kun afbrudt af op til 42 dages ferie
                            eller lignende i Danmark pr. løbende 6 måneder. Du definerer et
                            udlandsophold, ved at angive dato for din udrejse fra Danmark, samt dato
                            for din hjemkomst til Danmark minimum 6 måneder senere. ll33a.dk
                            beregner automatisk din minimums periode.
                        </p>
                    </div>
                    <SignupBelt includeLandingRef />
                    <Divider />
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                        }}
                        id="step-1"
                    >
                        <div style={{ width: 500 }}>
                            <h1 style={{ fontSize: 30 }}>
                                1. Gå til kalender-siden <CalendarOutlined />
                            </h1>
                            <p style={{ maxWidth: 400 }}>
                                Du starter et udlandsophold på en dato, hvor du forlader Danmark.
                                Ligesom et udlandsophold kun kan afsluttes på en dato, hvor du
                                ankommer til Danmark. I dette eksempel definerer vi et
                                udlandsophold, fra d.3. januar 2017.
                            </p>
                        </div>
                        <Img
                            title="Guide Image"
                            alt="step 1"
                            className="landing-guide-illustration"
                            fluid={data.s1.childImageSharp.fluid}
                        />
                    </div>
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                        }}
                        id="step-2"
                    >
                        <div style={{ width: 500 }}>
                            <h1 style={{ fontSize: 30 }}>
                                2. Tryk på "+ Add LL § 33A Exemption Period”
                            </h1>
                            <p style={{ maxWidth: 400 }}>
                                Under kalenderen kan du oprette dine udlandsophold, ved at trykke på
                                knappen: ”+ Add LL § 33A Exemption Period”.
                                <br />
                                <br />
                                Det er også her du finder oversigten over dine Udlandsophold
                                (Exemption periods), og hvor du efterfølgende kan redigere periodens
                                dato-spænd.
                            </p>
                        </div>
                        <Img
                            title="Guide Image"
                            alt="step 2"
                            style={{ marginBottom: 100 }}
                            className="landing-guide-illustration"
                            fluid={data.s2.childImageSharp.fluid}
                        />
                    </div>
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                        }}
                        id="step-3"
                    >
                        <div style={{ width: 500 }}>
                            <h1 style={{ fontSize: 30 }}>3. Giv dit udlandsophold en titel</h1>
                            <p style={{ maxWidth: 400 }}>
                                I menuen “Add a new Exemption period”, kan du valgfrit vælge en
                                titel til dit udlandsophold. F.eks. 'afrika tur'. Du kan altid
                                tilføje/redigere titel i oprettede udlandsophold.
                            </p>
                        </div>
                        <Img
                            title="Guide Image"
                            alt="step 3"
                            style={{ marginBottom: 100 }}
                            className="landing-guide-illustration"
                            fluid={data.s3.childImageSharp.fluid}
                        />
                    </div>
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                        }}
                        id="step-4"
                    >
                        <div style={{ width: 500 }}>
                            <h1 style={{ fontSize: 30 }}>4. Vælg start dato</h1>
                            <p style={{ maxWidth: 400 }}>
                                I menuen “Add a new Exemption period”, tryk på "Start" knappen for
                                at vælge start datoen.
                            </p>
                        </div>
                        <Img
                            title="Guide Image"
                            alt="step 4"
                            style={{ marginBottom: 100 }}
                            className="landing-guide-illustration"
                            fluid={data.s3.childImageSharp.fluid}
                        />
                    </div>
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                        }}
                        id="step-5"
                    >
                        <div style={{ width: 500 }}>
                            <h1 style={{ fontSize: 30 }}>5. Tryk på udrejsedagen</h1>
                            <p style={{ maxWidth: 400 }}>
                                Naviger i kalenderen ved hjælp pilene {'<< >>'} for årstal, og{' '}
                                {'< >'} for måneder, frem til måneden for udrejsedatoen. Tryk
                                herefter på dagen for din udrejse fra Danmark. (Bemærk at du senere
                                af Skattestyrelsen, vil kunne blive bedt om at dokumentere din
                                udrejse, hvorfor appen beder dig bekræfter at du forlod Danmark på
                                denne dato). Appen vil nu oprette en udrejse-dag i kalenderen.
                            </p>
                        </div>
                        <Img
                            title="Guide Image"
                            alt="step 5"
                            style={{ marginBottom: 100 }}
                            className="landing-guide-illustration"
                            fluid={data.s5.childImageSharp.fluid}
                        />
                    </div>
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                        }}
                        id="step-6"
                    >
                        <div style={{ width: 500 }}>
                            <h1 style={{ fontSize: 30 }}>6. Vælg slut dato</h1>
                            <p style={{ maxWidth: 400 }}>
                                En slutdato for et udlandsopholds ophør, vil altid ligge på en
                                ankomstdato til Danmark.
                                <br />
                                Slutdatoen for dit udlandsopholds ophør, fastlægges når dit
                                udlandsophold{' '}
                                <span style={{ textDecoration: 'underline' }}>
                                    afbrydes efter loven
                                </span>
                                .
                                <br />
                                Dit udlandsophold afbrydes efter loven, når du enten;
                                <br />
                                - rammer den 43. dag i DK inden for en løbende 6 måneder periode,
                                eller
                                <br />- tager arbejde i DK, hvor arbejdet{' '}
                                <span style={{ textDecoration: 'underline' }}>ikke</span> er direkte
                                i relation til dit udlandsophold.
                                <br />
                                Din slutdato vil derved være den ankomstdato til Danmark, der ligger
                                forud for udlandsopholdets afbrydelsen efter loven.
                                <br />
                                <br />
                                Skattestyrelsens styresignal fra 2009 beskriver det således:
                                <br />
                                <span className={classes.italic}>
                                    Lempelsesperiodens ophør (
                                    <ExternalLink to="https://www.skat.dk/skat.aspx?oid=1842649&lang=da">
                                        SKM2009.638.SKAT
                                    </ExternalLink>
                                    )
                                    <br />
                                    - Lempelsesperioden slutter, når udlandsopholdet afbrydes, det
                                    vil sige, når betingelserne for reglens anvendelse ikke længere
                                    er til stede - fx hvis ophold her i riget overstiger 42 dage
                                    indenfor en 6 måneders periode, eller hvis der udføres arbejde
                                    her i riget, som ikke er nødvendigt for udlandsopholdet.
                                    <br />- Skatteyderen kan ikke selv vælge at afslutte et
                                    udlandsophold på et tidspunkt, hvor det ikke afbrydes efter
                                    loven.
                                </span>
                                <br />
                                <br />
                                Hvis du endnu ikke kender din slutdato lader du blot datoen stå som
                                “Open End”.
                                <br />
                                <br />
                                Når du kender din slutdato, tryk på "End" knappen i menuen “Add a
                                new Exemption period”, for at vælge slut datoen for dit
                                udenlandsophold. Naviger i kalenderen ved hjælp pilene {'<< >>'} for
                                årstal, og {'< >'} for måneder, frem til måneden for
                                hjemrejsedatoen. Tryk herefter på dagen for din hjemrejse til
                                Danmark. (Bemærk at du senere af Skattestyrelsen, vil kunne blive
                                bedt om at dokumentere din hjemrejse, hvorfor appen beder dig
                                bekræfter at du ankom til Danmark på denne dato)
                            </p>
                        </div>
                        <div style={{ maxHeight: 130 }}>
                            <Img
                                title="Guide Image"
                                alt="step 6"
                                style={{ marginBottom: 100 }}
                                className="landing-guide-illustration"
                                fluid={data.s6.childImageSharp.fluid}
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                        }}
                        id="step-7"
                    >
                        <div style={{ width: 500 }}>
                            <h1 style={{ fontSize: 30 }}>7. Gem dit Udlandsophold</h1>
                            <p style={{ maxWidth: 400 }}>
                                I menuen “Add a new Exemption period”, tryk på den blå "Save" knap,
                                for at gemme og dermed oprette dit udlandsophold. Når dit
                                udlandsophold (Exemtion period) er oprettet, vil dine ferie dage i
                                Danmark, som du angiver i kalenderen få tildelt samme farve i
                                perioden.
                            </p>
                        </div>
                        <Img
                            title="Guide Image"
                            alt="step 7"
                            style={{ marginBottom: 100 }}
                            className="landing-guide-illustration"
                            fluid={data.s7.childImageSharp.fluid}
                        />
                    </div>
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                        }}
                        id="step-8"
                    >
                        <div style={{ width: 500 }}>
                            <h1 style={{ fontSize: 30 }}>8. Rediger dit Udlandsophold</h1>
                            <p style={{ maxWidth: 400 }}>
                                Når dit udlandsophold er oprettet, kan du efterfølgende redigere
                                titel, dato-spænd, samt farve. Du redigerer ved at trykke på
                                perioden under kalenderen, og herefter i menuen “Configure Exemption
                                period”.
                            </p>
                        </div>
                        <Img
                            title="Guide Image"
                            alt="step 8"
                            style={{ marginBottom: 100 }}
                            className="landing-guide-illustration"
                            fluid={data.s8.childImageSharp.fluid}
                        />
                    </div>
                    <SignupBelt includeLandingRef />
                </div>
            </span>
        </Container>
    )
}

export const query = graphql`
    query {
        s1: file(relativePath: { eq: "guide-assets/udlandsophold-step-1.png" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        s2: file(relativePath: { eq: "guide-assets/udlandsophold-step-2.png" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        s3: file(relativePath: { eq: "guide-assets/udlandsophold-step-3.png" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        s5: file(relativePath: { eq: "guide-assets/udlandsophold-step-5.png" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        s6: file(relativePath: { eq: "guide-assets/udlandsophold-step-6.png" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        s7: file(relativePath: { eq: "guide-assets/udlandsophold-step-7.png" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        s8: file(relativePath: { eq: "guide-assets/udlandsophold-step-8.png" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
    }
`
